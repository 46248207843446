<template>
  <v-container
    class="px-5 pt-8"
    fluid
  >
    <template v-if="account && availableToDrawnDown">
      <v-row class="mb-8">
        <app-header
          title="Dashboard"
          :subtitle="`Your wallet: ${account}`"
          :right="`Cliff: ${cliff} day(s) Vesting period: ${vestingPeriod}`"
        />
      </v-row>

      <!-- Dashboard content -->
      <v-row>
        <v-col
          sm="12"
          md="5"
        >
          <balance-card
            :balance="balance"
            :unvested="unvested"
            :entitlement="entitlement"
            :completed-vesting="completedVesting"
          />

          <withdraw-card
            v-if="availableToDrawnDown && !completedVesting"
            :balance="balance"
            @draw-down="drawDown"
          />

          <h3></h3>
        </v-col>
        <v-col
          sm="12"
          md="7"
        >
          <transaction-table :transactions="transactionHistory" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-alert
            dense
            type="info"
          >
            CUDOS staking is now live in the <a href="https://explorer.cudos.org/">Cudos Mainnet</a>! Use our <a href="https://bridge.cudos.org/">Cudos Bridge</a> to migrate your tokens to our mainnet, and then head to our <a href="https://dashboard.cudos.org/">dashboard</a> to stake them and start earning rewards today.
          </v-alert>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import web3Connect from '@/web3ConnectService'
import differenceInMonths from 'date-fns/differenceInMonths'

import AppHeader from '@/components/AppHeader'
import BalanceCard from '@/components/Dashboard/BalanceCard'
import WithdrawCard from '@/components/Dashboard/WithdrawCard'
import TransactionTable from '@/components/Dashboard/TransactionTable'

export default {
  name: 'Home',
  components: {
    AppHeader,
    BalanceCard,
    WithdrawCard,
    TransactionTable
  },
  data () {
    return {
      polling: null
    }
  },
  computed: {
    ...mapState([
      'account',
      'accountBalance',
      'schedule',
      'availableToDrawnDown',
      'transactionHistory',
      'web3'
    ]),
    ...mapGetters([
      'hasValidSchedule',
      'toEther',
      'toEtherFixed'
    ]),
    vestingPeriod () {
      const monthDiff = differenceInMonths(this.toDate(this.schedule._end), this.toDate(this.schedule._start))
      return monthDiff > 1 ? `${monthDiff + 1} months` : `1 month`
    },
    balance () {
      return this.toEtherFixed(this.availableToDrawnDown, 2)
    },
    unvested () {
      return this.toEtherFixed(this.schedule._remainingBalance, 2)
    },
    entitlement () {
      return this.toEtherFixed(this.schedule._amount, 2)
    },
    cliff () {
      return this.toEtherFixed(this.schedule._cliff, 0)
    },
    completedVesting () {
      return this.unvested === '0.00'
    }
  },
  beforeDestroy () {
    clearInterval(this.polling)
  },
  created () {
    this.pollData()

    web3Connect.on('connect', provider => {
      this.$store.dispatch('bootstrap', provider)
    })
  },
  methods: {
    toDate (timestampInSecs) {
      return new Date(timestampInSecs * 1000)
    },
    drawDown () {
      this.$store.dispatch('drawDown')
    },
    pollData () {
      this.polling = setInterval(() => {
        this.$store.dispatch('availableDrawDownAmount')
      }, 10000)
    }
  }
}
</script>

import _ from 'lodash'
import Vue from 'vue'
import Vuex from 'vuex'

import * as Web3 from 'web3'

import notifier from '../notifier'

Vue.use(Vuex)

const CudosTokenJson = require('../truffle/CudosToken')
const VestingContractJson = require('../truffle/VestingContract')

const whitelistForViewingAdminPage = [
  '0x16cb06B0f0a247d5b19c7a9Fc4BA88A975684538', // CUDO deploy account
  '0xD677AEd0965AC9B54e709F01A99cEcA205aebC4B', // Prototype BR
  '0xbdfc83d3f57636ff727ed9abd8473f0318cd6da6',
  '0xe0a8fba53a3f1fb9da64248b5782bdc0ada189df',
  '0xf3c53fd84daff8da6938368b6a7a2c27f6369cc2',
  '0x3e79ddc2911f5a622db65d1e92742f9b3d22de4e',
  '0x8F8496F0C809C0209e72A4cC3896c2Cb90aFb9E4',
  '0x834aAFC40c8E3Db8e12CED83576Dc62495200F84', // dom
  '0x0ff7cF7455e093B31C9E71445C4b30837701cd55', // lee
  '0x671cF922Cec092dA495785532c59c1cB4208aCa2', // matt
  '0x387a003e901b22dAaA4a2C20A574D1708Edd594c', // pete
  '0x5C7a59E25C01Cc639812f115458cA5B526A99cED', // nuno
  '0x655f505fB23A940B5b73e39980032D1FF12A984C', // flav
  '0x386Bc6fDD5B9Ad170c44955A0acee9dB7B3e1B3b' // neethu
]

export default new Vuex.Store({
  state: {
    // Network
    networkId: 1,
    networkName: 'Mainnet',
    etherscanBase: 'https://etherscan.io',

    // Account
    account: null,
    accountBalance: null,

    // Vesting
    schedule: null,
    availableToDrawnDown: null,
    transactionHistory: [],

    web3: null,
    notifyInstance: null,

    // Contracts
    cudosTokenContract: null,
    vestingContract: null
  },
  mutations: {
    networkDetails (state, { networkId, networkName, etherscanBase }) {
      state.networkId = networkId
      state.networkName = networkName
      state.etherscanBase = etherscanBase
      state.notifyInstance = notifier(networkId)

      state.cudosTokenContract = new state.web3.eth.Contract(CudosTokenJson.abi, CudosTokenJson.networks[state.networkId].address)
      state.vestingContract = new state.web3.eth.Contract(VestingContractJson.abi, VestingContractJson.networks[state.networkId].address)
    },

    account (state, account) {
      state.account = account
    },

    balanceOfAccount (state, balanceOfAccount) {
      state.accountBalance = balanceOfAccount
    },

    schedule (state, schedule) {
      state.schedule = schedule
    },

    availableToDrawnDown (state, availableToDrawnDown) {
      state.availableToDrawnDown = availableToDrawnDown
    },

    transactionHistory (state, transactionHistory) {
      state.transactionHistory = transactionHistory
    },

    web3 (state, web3) {
      state.web3 = web3
    }
  },
  getters: {
    isConnected: () => {
      return window.web3 !== undefined
    },

    etherscanTokenLink: state => (tokenId) => {
      const networkAddress = CudosTokenJson.networks[state.networkId].address
      return `${state.etherscanBase}/token/${networkAddress}?a=${tokenId}`
    },

    etherscanTransactionLink: state => (txs) => {
      return `${state.etherscanBase}/tx/${txs}`
    },

    hasValidSchedule: state => () => {
      if (state.schedule) {
        return state.schedule._start && state.schedule._start > 0
      }

      return false
    },

    toEther: state => (wei) => state.web3.utils.fromWei(wei, 'ether'),

    toEtherFixed: state => (wei, dp) => parseFloat(state.web3.utils.fromWei(wei, 'ether')).toFixed(dp),

    vestingContract: state => state.vestingContract,

    getHistoriesForAddress: state => async (account) => {
      const scheduleCreated = await state.vestingContract.getPastEvents('ScheduleCreated', {
        filter: {
          _beneficiary: account
        },
        fromBlock: 0,
        toBlock: 'latest'
      })

      const drawDown = await state.vestingContract.getPastEvents('DrawDown', {
        filter: {
          _beneficiary: account
        },
        fromBlock: 0,
        toBlock: 'latest'
      })

      return Promise.all([
        scheduleCreated,
        drawDown
      ])
        .then(async ([scheduleCreatedEvents, drawDownEvents]) => {
          // console.log(scheduleCreatedEvents, drawDownEvents)

          const mappedScheduleCreatedEvents = await Promise.all(scheduleCreatedEvents.map((event) => {
            return state.web3.eth.getBlock(event.blockNumber)
              .then(({ timestamp }) => {
                return {
                  date: timestamp,
                  event: event.event,
                  status: 'Created',
                  blockHash: event.blockHash,
                  blockNumber: event.blockNumber,
                  transactionHash: event.transactionHash,
                  // event data
                  beneficiary: event.returnValues._beneficiary,
                  amount: event.returnValues._amount,
                  start: event.returnValues._start,
                  duration: event.returnValues._duration
                }
              })
          }))

          const mappedDrawDownEvents = await Promise.all(drawDownEvents.map((event) => {
            return state.web3.eth.getBlock(event.blockNumber)
              .then(({ timestamp }) => {
                return {
                  date: timestamp,
                  event: event.event,
                  status: 'Complete',
                  blockHash: event.blockHash,
                  blockNumber: event.blockNumber,
                  transactionHash: event.transactionHash,
                  // event data
                  beneficiary: event.returnValues._beneficiary,
                  amount: event.returnValues._amount,
                  time: event.returnValues._time
                }
              })
          }))

          return [
            ...mappedDrawDownEvents,
            ...mappedScheduleCreatedEvents
          ].sort((a, b) => b.blockNumber - a.blockNumber)
        })
        .catch(function (e) {
          console.log('Failed to get events', e)
        })
    },

    account: state => state.account,

    canAccountViewAdmin: state => (account) => _.includes(whitelistForViewingAdminPage, account)
  },
  actions: {
    bootstrap ({ dispatch }, provider) {
      dispatch('loginWeb3', provider)
    },

    /// //////////////////////
    // Web3 initialisation //
    /// //////////////////////

    async loginWeb3 ({ dispatch, state }, provider) {
      if (!state.account) {
        window.web3 = new Web3(provider)

        if (window.ethereum) {
          window.ethereum.on('accountsChanged', (accounts) => {
            dispatch('initWeb3', window.web3)
          })
        }

        dispatch('initWeb3', window.web3)
      }
    },

    async initWeb3 ({ commit, dispatch, state }, web3) {
      commit('web3', web3)

      await dispatch('getNetwork')

      state.web3.eth.getAccounts(async (error, accounts) => {
        if (!error) {
          const account = accounts[0]
          commit('account', account)

          dispatch('balanceOfAccount')

          await dispatch('vestingScheduleForBeneficiary')

          dispatch('availableDrawDownAmount')

          dispatch('getHistoriesForAddress')
        } else {
          console.log(`Error getting accounts`, error)
        }
      })
    },

    async getNetwork ({ commit, dispatch }) {
      const networkId = await dispatch('getNetworkId')
      const networkName = await dispatch('getNetworkName', networkId)
      const etherscanBase = await dispatch('getEtherscanAddress', networkId)
      console.log(`Running on network ID [${networkId}] with etherscan base [${etherscanBase}]`)
      return commit('networkDetails', { networkId, networkName, etherscanBase })
    },

    getNetworkId ({ state }) {
      return state.web3.eth.net.getId()
    },

    getEtherscanAddress ({ state }, networkId) {
      switch (networkId) {
        case 1:
          return 'https://etherscan.io'
        case 4:
          return 'https://rinkeby.etherscan.io'
        default:
          return ''
      }
    },

    getNetworkName ({ state }, networkId) {
      switch (networkId) {
        case 1:
          return 'MAINNET'
        case 4:
          return 'RINKEBY'
        case 5777:
          return 'LOCAL'
        default:
          return 'UNKNOWN'
      }
    },

    /// //////////////////
    // Token calls    //
    /// /////////////////

    async balanceOfAccount ({ commit, state }) {
      const balanceOfAccount = await state.cudosTokenContract.methods.balanceOf(state.account).call()
      commit('balanceOfAccount', balanceOfAccount)
    },

    /// //////////////////
    // Vesting calls   //
    /// /////////////////

    async vestingScheduleForBeneficiary ({ commit, state }) {
      const schedule = await state.vestingContract.methods.vestingScheduleForBeneficiary(state.account).call()
      commit('schedule', schedule)
    },

    async availableDrawDownAmount ({ commit, state, getters }) {
      if (getters.hasValidSchedule(state)) {
        const availableToDrawnDown = await state.vestingContract.methods.availableDrawDownAmount(state.account).call()
        commit('availableToDrawnDown', availableToDrawnDown)
      }
    },

    drawDown ({ dispatch, state }) {
      return new Promise((resolve, reject) => {
        state.vestingContract.methods.drawDown()
          .send({
            from: state.account
          })
          .once('transactionHash', (hash) => {
            // notification popup
            state.notifyInstance.hash(hash)

            resolve(hash)
          })
          .on('receipt', () => {
            dispatch('balanceOfAccount')

            dispatch('vestingScheduleForBeneficiary')

            dispatch('availableDrawDownAmount')

            dispatch('getHistoriesForAddress')
          })
          .on('error', reject)
      })
    },

    async getHistoriesForAddress ({ state, commit }) {
      const account = state.account
      const scheduleCreated = await state.vestingContract.getPastEvents('ScheduleCreated', {
        filter: {
          _beneficiary: account
        },
        fromBlock: 0,
        toBlock: 'latest'
      })

      const drawDown = await state.vestingContract.getPastEvents('DrawDown', {
        filter: {
          _beneficiary: account
        },
        fromBlock: 0,
        toBlock: 'latest'
      })

      return Promise.all([
        scheduleCreated,
        drawDown
      ])
        .then(async ([scheduleCreatedEvents, drawDownEvents]) => {
          // console.log(scheduleCreatedEvents, drawDownEvents)

          const mappedScheduleCreatedEvents = await Promise.all(scheduleCreatedEvents.map((event) => {
            return state.web3.eth.getBlock(event.blockNumber)
              .then(({ timestamp }) => {
                return {
                  date: timestamp,
                  event: event.event,
                  status: 'Created',
                  blockHash: event.blockHash,
                  blockNumber: event.blockNumber,
                  transactionHash: event.transactionHash,
                  // event data
                  beneficiary: event.returnValues._beneficiary,
                  amount: event.returnValues._amount,
                  start: event.returnValues._start,
                  duration: event.returnValues._duration
                }
              })
          }))

          const mappedDrawDownEvents = await Promise.all(drawDownEvents.map((event) => {
            return state.web3.eth.getBlock(event.blockNumber)
              .then(({ timestamp }) => {
                return {
                  date: timestamp,
                  event: event.event,
                  status: 'Complete',
                  blockHash: event.blockHash,
                  blockNumber: event.blockNumber,
                  transactionHash: event.transactionHash,
                  // event data
                  beneficiary: event.returnValues._beneficiary,
                  amount: event.returnValues._amount,
                  time: event.returnValues._time
                }
              })
          }))

          const events = [
            ...mappedDrawDownEvents,
            ...mappedScheduleCreatedEvents
          ].sort((a, b) => b.blockNumber - a.blockNumber)

          commit('transactionHistory', events)
        })
        .catch(function (e) {
          console.log('Failed to get events', e)
        })
    }
  }
})

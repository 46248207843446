<template>
  <v-container
    class="px-5 pt-8"
  >
    <!-- Problems signing in content -->
    <v-row
      class="py-8"
    >
      <v-btn
        icon
        color="secondary"
        x-large
        exact
        :to="{name: 'dashboard'}"
      >
        <v-icon color="secondary">
          mdi-arrow-left
        </v-icon>
      </v-btn>
      <v-col
        cols="12"
      >
        <h1 class="display-2 font-weight-bold secondary--text my-8">
          Problems signing in
        </h1>
        <v-divider class="mb-10 secondary lighten-5" />

        <h2 class="secondary--text mb-3">
          Check your MetaMask wallet
        </h2>
        <p class="secondary--text text--lighten-3">
          Make sure that you have connected using the MetaMask wallet which you sent us to receive the tokens. Otherwise you will not be able to log in.
        </p>
        <p class="secondary--text text--lighten-3">
          Please make sure as well that your MetaMask wallet is connected to Ethereum Mainnet, and not to any Test Networks.
        </p>

        <h2 class="secondary--text mb-3">
          If all else fails
        </h2>
        <p class="secondary--text text--lighten-3">
          If none of the above checks have solved your problem, please get in touch with us at token@cudos.org explaining the issue using the button below.
        </p>
        <contact-cudos-button color="secondary" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ContactCudosButton from '@/components/ContactCudosButton'

export default {
  name: 'ProblemSigningIn',
  components: {
    ContactCudosButton
  }
}
</script>

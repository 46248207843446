<template>
  <v-card
    class="text-left dark-card mb-6"
    flat
  >
    <v-container>
      <v-row>
        <v-col>
          <v-toolbar
            class="transparent ma-0 pa-0"
            flat
          >
            <v-toolbar-title
              v-if="completedVesting"
              style="font-size:1.3rem; padding-top:1rem;"
            >
              <h2
                class="subheading"
                v-text="'Thank you for your purchase!'"
              />
            </v-toolbar-title>
            <v-toolbar-title
              v-else
              class="ma-0 pa-0"
            >
              <h2
                class="subheading"
                v-text="'Balance'"
              />
              <p
                class="overline"
                v-text="'Your CUDOS balance'"
              />
            </v-toolbar-title>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text>
        <v-row>
          <v-col>
            <p
              class="overline mb-1"
              v-text="!completedVesting ? 'Available now' : 'Total withdrawn'"
            />
            <v-text-field
              :value=" !completedVesting ? `${animatedBalance} CUDOS` : `${animatedEntitlement} CUDOS`"
              readonly
            >
              <slot slot="append">
                <img
                  slot
                  style="margin-bottom:.75rem"
                  :src="cudosToken"
                >
              </slot>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="!completedVesting">
            <p
              class="overline mb-2"
              v-text="'Remaining'"
            />
            <h3>
              {{ animatedUnvested }} <small>CUDOS</small>
            </h3>
          </v-col>
          <v-col v-if="!completedVesting">
            <p
              class="overline mb-2"
              v-text="'Allocation'"
            />
            <h3>
              {{ animatedEntitlement }} <small>CUDOS</small>
            </h3>
          </v-col>
        </v-row>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import cudosToken from '@/assets/img/cudos.svg'
import { TweenLite } from 'gsap'
export default {
  name: 'BalanceCard',
  props: {
    balance: {
      type: String,
      default: '0'
    },
    unvested: {
      type: String,
      default: '0'
    },
    entitlement: {
      type: String,
      default: '0'
    },
    completedVesting: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      cudosToken,
      tweenedBalance: 0,
      tweenedUnvested: 0,
      tweenedEntitlement: 0
    }
  },
  computed: {
    animatedBalance () {
      return parseFloat(this.tweenedBalance).toFixed(2)
    },
    animatedUnvested () {
      return parseFloat(this.tweenedUnvested).toFixed(2)
    },
    animatedEntitlement () {
      return parseFloat(this.tweenedEntitlement).toFixed(0)
    }
  },

  watch: {
    balance: function (newValue) {
      TweenLite.to(this, 1, { tweenedBalance: newValue })
    },
    unvested: function (newValue) {
      TweenLite.to(this, 1, { tweenedUnvested: newValue })
    },
    entitlement: function (newValue) {
      TweenLite.to(this, 1, { tweenedEntitlement: newValue })
    }
  },
  created () {
    this.tweenedBalance = this.balance
    this.tweenedUnvested = this.unvested
    this.tweenedEntitlement = this.entitlement
  }
}
</script>

<template>
  <v-navigation-drawer
    v-model="shouldShowNav"
    :app="account && hasValidSchedule()"
    :absolute="!account || !hasValidSchedule()"
    class="transparent"
    floating
    :permanent="!account || !hasValidSchedule()"
    :style="!account || !hasValidSchedule() ? 'pointer-events:none' : null"
  >
    <v-overlay
      v-if="account && hasValidSchedule()"
      :absolute="false"
      :opacity="$vuetify.breakpoint.lgAndUp ? .4 : .9"
      color="secondary"
      value="true"
      z-index="-1"
    />
    <v-layout
      class="app-drawer"
      column
    >
      <v-flex>
        <div
          :class="$vuetify.breakpoint.mdAndUp || account && hasValidSchedule() ? 'text-center py-8' : 'px-3 py-3'"
        >
          <img
            alt="CUDOS"
            :src="logo"
            :style="{maxWidth: '140px'}"
          >
        </div>
        <v-divider
          v-if="account && hasValidSchedule()"
          style="max-width:80%; margin:0 auto"
        />

        <v-list
          v-if="account && hasValidSchedule()"
          nav
          flat
        >
          <v-list-item
            v-for="item in items"
            :key="item.title"
            exact
            :to="{name: item.name}"
          >
            <v-list-item-icon>
              <v-icon
                color="white"
                v-text="item.icon"
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="font-weight-bold"
                v-text="item.title"
              />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-flex>

      <v-flex
        class="d-flex align-end"
        :class="$vuetify.breakpoint.mdAndUp ? 'justify-center' : 'justify-start'"
      >
        <div
          :class="$vuetify.breakpoint.mdAndUp || account && hasValidSchedule() ? 'py-8' : 'px-3 py-3'"
        >
          <v-divider
            v-if="account && hasValidSchedule()"
            style="margin:0 auto"
            class="mb-5"
          />
          <img
            alt="Powered by Cudo"
            :src="poweredByCudo"
            :style="{ maxWidth: '120px' }"
          >
        </div>
      </v-flex>
    </v-layout>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import logo from '@/assets/img/cudos-logo.svg'
import poweredByCudo from '@/assets/img/powered-by-cudo.svg'

export default {
  name: 'AppNavigation',
  props: {
    account: {
      type: String,
      default: null
    },
    accountBalance: {
      type: String,
      default: null
    },
    showNav: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      logo,
      poweredByCudo
    }
  },
  computed: {
    ...mapGetters([
      'toEther',
      'toEtherFixed',
      'hasValidSchedule',
      'account',
      'canAccountViewAdmin'
    ]),
    items () {
      const elements = [
        {
          title: 'Dashboard',
          name: 'dashboard',
          icon: 'mdi-home'
        },
        {
          title: 'FAQs',
          name: 'faq',
          icon: 'mdi-help-circle-outline'
        }
      ]

      if (this.account) {
        const canViewAdmin = this.canAccountViewAdmin(this.account)

        if (canViewAdmin) {
          elements.push({
            title: 'Admin',
            name: 'admin',
            icon: 'mdi-help-circle-outline'
          })
        }
      }

      return elements
    },
    shouldShowNav: {
      get () {
        return this.showNav
      },
      set (val) {
        this.$emit('navigation-toggled', val)
      }
    }
  }
}
</script>

<template>
  <v-app
    :dark="true"
    :style="shouldShowNav ? `background-image:url('${currentBackground}')` : 'background-color:white'"
  >
    <app-navigation
      v-if="shouldShowNav"
      :show-nav="showNav"
      :account="account"
      :account-balance="accountBalance"
      @navigation-toggled="toggleNav"
    />
    <v-content>
      <v-btn
        v-if="$vuetify.breakpoint.mdAndDown && account && shouldShowNav && hasValidSchedule()"
        x-large
        icon
        @click="toggleNav"
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <transition
        name="page"
        mode="out-in"
      >
        <router-view />
      </transition>
    </v-content>
  </v-app>
</template>

<script>
import authBg from './assets/img/auth-bg.jpg'
import bg from './assets/img/bg.jpg'

import { mapState, mapGetters } from 'vuex'

import AppNavigation from '@/components/AppNavigation'

export default {
  name: 'App',
  components: {
    AppNavigation
  },
  data () {
    return {
      mintingExpanded: true,
      showNav: true,
      backgrounds: {
        main: bg,
        auth: authBg
      }
    }
  },
  computed: {
    ...mapState([
      'account',
      'accountBalance'
    ]),
    ...mapGetters([
      'hasValidSchedule'
    ]),
    currentBackground () {
      return this.account && this.hasValidSchedule() ? this.backgrounds.main : this.backgrounds.auth
    },
    shouldShowNav () {
      return this.$route.name !== 'problemSigningIn'
    }
  },
  methods: {
    toggleNav (val) {
      this.showNav = val
    }
  }
}
</script>

<style lang="scss">
@import "@/scss/main.scss";

#app {
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-image 0.2s ease-in-out;
}
</style>

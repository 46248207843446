<template>
  <v-toolbar
    class="section-header transparent pt-2 mb-2"
    :class="{'pt-5 mb-8': $vuetify.breakpoint.smAndDown }"
    :dense="true"
    text
    :elevation="0"
  >
    <!-- FAQ heading -->
    <v-container fluid>
      <v-row>
        <v-col
          class="pa-0 ma-0"
          :cols="12"
          :sm="8"
        >
          <v-toolbar-title>
            <h2
              class="subheading"
              v-text="title"
            />
            <p
              class="overline"
              v-text="subtitle"
            />
          </v-toolbar-title>
        </v-col>
        <v-col
          v-if="right"
          class="pa-0 ma-0 d-flex"
          :class="{'justify-end align-end' : $vuetify.breakpoint.smAndUp}"
          :cols="12"
          :sm="4"
        >
          <p
            class="overline"
            v-text="right"
          />
        </v-col>
        <v-col
          cols="12"
          class="pa-0 ma-0"
        >
          <v-divider />
        </v-col>
      </v-row>
    </v-container>
  </v-toolbar>
</template>

<script>
export default {
  name: 'Appheader',
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: null
    },
    right: {
      type: String,
      default: null
    }
  }
}
</script>

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import Dashboard from '../views/Dashboard.vue'
import SignIn from '../views/SignIn'
import Faq from '../views/Faq'
import ProblemSigningIn from '../views/ProblemSigningIn'
import NotFound from '../views/NotFound'
import Admin from '../views/Admin'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sign-in',
    name: 'signIn',
    component: SignIn
  },
  {
    path: '/faq',
    name: 'faq',
    component: Faq,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/problem-signing-in',
    name: 'problemSigningIn',
    component: ProblemSigningIn
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin
  },
  {
    path: '/*',
    name: 'notFound',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, _from, next) => {
  if (to.meta.requiresAuth && !store.state.account) {
    next({
      name: 'signIn'
    })
  } else if (to.name === 'signIn' && store.state.account) {
    next({
      name: 'dashboard'
    })
  } else {
    next()
  }
})

export default router

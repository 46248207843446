<template>
  <v-card
    class="text-left dark-card"
    flat
  >
    <v-container>
      <v-row>
        <v-col>
          <v-toolbar
            class="transparent ma-0 pa-0"
            flat
          >
            <v-toolbar-title class="ma-0 pa-0">
              <h2
                class="subheading"
                v-text="'Withdraw'"
              />
              <p
                class="overline"
                v-text="'Withdraw available balance'"
              />
            </v-toolbar-title>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text>
        <v-row>
          <v-col>
            <v-btn
              outlined
              block
              large
              :disabled="loading || (balance === '0.00')"
              @click="handleWithdrawalClick"
              v-text="!loading ? `Withdraw ${balance} CUDOS` : `Please wait ${timeout} seconds`"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'WithdrawCard',
  props: {
    balance: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      delay: 20,
      timeout: 0,
      interval: null,
      loading: false
    }
  },
  methods: {
    handleWithdrawalClick () {
      this.withdrawWithDelay()
    },

    withdrawWithDelay () {
      this.timeout = this.delay

      this.loading = true
      this.$emit('draw-down')

      this.interval = setInterval(() => {
        this.timeout !== 1 ? this.timeout = this.timeout - 1 : this.timeout = this.delay
      }, 1000)

      setTimeout(() => {
        clearInterval(this.interval)
        this.loading = false
      }, this.delay * 1000)
    }

  }
}
</script>

<template>
  <v-btn
    outlined
    :color="color"
    href="mailto:token@cudos.org?subject=I need help signing in to the CUDOS Token Holder Portal"
    v-text="'Contact CUDOS'"
  />
</template>

<script>
export default {
  name: 'ContactCudosButton',
  props: {
    color: {
      type: String,
      default: 'white'
    }
  }
}
</script>

<template>
  <v-container
    class="px-5 pt-8"
    fluid
  >
    <v-row
      class="mb-8"
    >
      <app-header
        title="FAQs"
        subtitle="Frequently asked questions"
      />
    </v-row>

    <!-- FAQ content -->
    <v-row>
      <v-col
        v-for="(question, index) in faqs"
        :key="index"
        cols="12"
      >
        <faq-card
          :title="question.title"
          :body="question.body"
          :should-show-contact-button="question.shouldShowContactButton"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppHeader from '@/components/AppHeader'
import FaqCard from '@/components/Faq/FaqCard'

export default {
  name: 'Faq',
  components: {
    AppHeader,
    FaqCard
  },
  data () {
    return {
      faqs: [
        {
          title: 'What is distributed computing?',
          body: [
            'Distributed computing is field of computer science which deals with distributed systems, where the components of a system are distributed across networked computers. These computers communicate and coordinate actions through messaging each other. The collective computing power of larger distributed computing networks form supercomputers, that can be used for many functions including data science, medical research, rendering, and cryptocurrency mining.',
            'There are predominantly two user groups that see the greatest benefits from this model. These benefits are most commonly perceived to be:',
            'Asset owner (providing computing power)',
            '– The ability to monetise spare compute resource',
            'Purchaser (consumer of computing power)',
            '– Removes CapEx cost of acquiring hardware',
            '– Ability to scale computing power on demand',
            'Our vision at Cudo is to create the largest distributed computing platform for good.'
          ]

        },
        {
          title: ' What is Proof of Stake (PoS)?',
          body: 'Proof of Stake is an alternative to the first consensus mechanism, Proof of Work (PoW). While PoW involves a lot of computation power for all nodes, PoS decides consensus based on the stake (the coins/tokens) that each node has on the network.'
        },
        {
          title: 'What are layer 2 networks?',
          body: 'While layer 1 networks, also referred to as the main chain, are blockchain systems such as Bitcoin or Ethereum, with typically low-transaction throughput, layer 2 networks are secondary networks built on top of the underlying layer 1 blockchain, typically solving layer 1 issues such as scalability.'
        },
        {
          title: 'What are compute oracles?',
          body: 'Compute oracles are bridges between blockchains and the outside world to enable the former to consume outside API’s and compute power.'
        },
        {
          title: 'What is the layer 3 network (Cudo)?',
          body: 'The Cudo platform provides cloud-like services, focusing on low costs, low latency and a high degree of personalisation. Hence, just like the CUDOS network is a layer added on top of blockchains to provide extra compute capacity, the Cudo platform can be seen as an extra layer on top of the CUDOS network, and thus the layer 3 to blockchains, to provide even more on-demand compute capacity. This added capacity might be needed in order to select different types of hardware, or to request jobs that demand more resources than the CUDOS network can directly absorb.'
        },
        {
          title: 'Did we answer your question?',
          body: "If we haven't been able to answer your question, please contact CUDOS using the button below.",
          shouldShowContactButton: true
        }
      ]
    }
  }

}
</script>

<template>
  <v-card
    style="background-color:transparent; max-width:480px"
    class="text-left"
    flat
  >
    <h1
      class="display-2 font-weight-bold mb-3"
      v-text="'CUDOS Token Holder Portal'"
    />
    <p
      class="body-1"
      v-text="'This is where you can view and manage your vested CUDOS tokens.'"
    />
    <v-btn
      outlined
      white
      large
      :loading="loading"
      @click="signIn"
      v-text="!loading ? 'Sign in' : null"
    />
    <router-link
      :to="{name: 'problemSigningIn'}"
      class="mt-5 d-block"
      v-text="'Problems signing in?'"
    />
  </v-card>
</template>

<script>
export default {
  name: 'SignInCard',
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    signIn () {
      this.$emit('sign-in-click')
    }
  }
}
</script>


import Web3Connect from 'web3connect'

// @ts-ignore
import Portis from '@portis/web3'

// @ts-ignore
const web3Connect = new Web3Connect.Core({
  providerOptions: {
    portis: {
      package: Portis, // required
      options: {
        id: 'fb24fdc2-bf92-4005-ac6e-17b7b5e7db70' // FIXME - get CUDO key
      }
    }
  }
})

export default web3Connect

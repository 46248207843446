<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row>
      <v-col
        class="d-flex flex-column justify-center align-center"
      >
        <h1
          class="display-4"
          v-text="'404'"
        />
        <p>Page not found</p>
        <v-btn
          :to="{name: 'dashboard'}"
          exact
          large
          outlined
          v-text="'Back'"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

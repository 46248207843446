import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

Vue.filter('dotDotDot', function (value) {
  if (!value) return ''
  return value.substr(0, 6) + '...' + value.substr(value.length - 6, value.length)
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

<template>
  <v-card
    class="text-left dark-card px-0 mx-0"
    flat
  >
    <v-container fluid>
      <v-row>
        <v-col>
          <v-toolbar
            class="transparent ma-0 pa-0"
            flat
          >
            <v-toolbar-title class="ma-0 pa-0">
              <h2
                class="subheading"
                v-text="'Transactions'"
              />
              <p
                class="overline"
                v-text="'Your transaction history'"
              />
            </v-toolbar-title>
          </v-toolbar>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      fluid
      class="pa-0"
    >
      <v-row
        no-gutters
      >
        <v-col>
          <v-simple-table
            v-if="$vuetify.breakpoint.mdAndUp"
            class="transparent"
            style="max-height:580px; overflow-y:scroll"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="pl-8"
                    v-text="'Amount'"
                  />
                  <th
                    class="pl-8"
                    v-text="'Transaction Hash'"
                  />
                  <th
                    class="pl-8"
                    v-text="'Date'"
                  />
                  <th
                    class="pl-8"
                    v-text="'Status'"
                  />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="transaction in transactions"
                  :key="transaction.transactionHash"
                >
                  <td
                    class="pl-8"
                  >
                    <img
                      :src="cudosToken"
                      width="15rem"
                      height="15rem"
                      class="mr-3"
                      style="position:relative; top:.1rem;"
                    > {{ toEtherFixed(transaction.amount, 2) }}
                  </td>
                  <td
                    class="pl-8"
                  >
                    <a
                      :href="etherscanTransactionLink(transaction.transactionHash)"
                      target="_blank"
                    >
                      {{ transaction.transactionHash | dotDotDot }}
                    </a>
                  </td>
                  <td
                    class="pl-8"
                    v-text="asDate(transaction.date)"
                  />
                  <td
                    class="pl-8"
                    v-text="transaction.status"
                  />
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-list
            v-else
            class="transparent"
          >
            <v-list-group-item-group>
              <v-list-item
                v-for="transaction in transactions"
                :key="transaction.transactionHash"
                :href="etherscanTransactionLink(transaction.transactionHash)"
                target="_blank"
              >
                <v-list-item-content>
                  <v-list-item-title class="body-2 ">
                    <img
                      :src="cudosToken"
                      width="15rem"
                      height="15rem"
                      class="mr-2"
                      style="position:relative; top: .14rem;"
                    >{{ toEtherFixed(transaction.amount, 2) }}
                    <p class="ma-0 overline">
                      {{ transaction.transactionHash | dotDotDot }}
                    </p>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text v-text="`${transaction.status} ${asDate(transaction.date)}`" />
                </v-list-item-action>
              </v-list-item>
            </v-list-group-item-group>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import cudosToken from '@/assets/img/cudos.svg'
import { mapGetters } from 'vuex'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'

export default {
  name: 'TransactionTable',
  props: {
    transactions: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      cudosToken
    }
  },
  computed: {
    ...mapGetters([
      'toEtherFixed',
      'etherscanTransactionLink'
    ])
  },
  methods: {
    asDate (timestampInSecs) {
      return `${formatDistanceToNow(new Date(timestampInSecs * 1000))} ago`
    }
  }
}
</script>

<template>
  <v-container
    class="px-5 pt-8"
    fluid
  >
    <v-row
      class="mb-7"
    >
      <v-col cols="12">
        <div class="mt-3">
          <h1>Lookup Vesting Schedule</h1>
          <v-text-field class="mt-3" label="0x123..." v-model="scheduleOwner" @input="clear"></v-text-field>
          <v-btn @click="getVestingSchedule">
            Find
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row
      class="mb-8"
      v-if="scheduleOwner && schedule && parseInt(schedule._drawDownRate) > 0"
    >
      <v-col cols="12">
        <div class="mt-2">
          <h3 class="mb-2">Schedule Found for [{{scheduleOwner}}]</h3>
          <div>Amount: {{scheduleWithHumanReadableValues.amount}} CUDO</div>
          <div>Total Drawn: {{scheduleWithHumanReadableValues.totalDrawn}} CUDO</div>
          <div>Unclaimed balance: {{scheduleWithHumanReadableValues.remainingBalance}} CUDO</div>
          <div>Draw down rate: {{scheduleWithHumanReadableValues.drawDownRate}} CUDO/sec</div>
          <div>Start: {{scheduleWithHumanReadableValues.start}}</div>
          <div>End: {{scheduleWithHumanReadableValues.end}}</div>
          <div>Cliff: {{scheduleWithHumanReadableValues.cliff}}</div>
          <div>Last Drawn At: {{scheduleWithHumanReadableValues.lastDrawnAt}}</div>
        </div>
        <div class="mt-7">
          <transaction-table :transactions="scheduleEvents" />
        </div>
      </v-col>
    </v-row>
    <v-row
      class="mb-8"
      v-else-if="scheduleOwner && schedule && parseInt(schedule._drawDownRate) === 0"
    >
      <v-col cols="12">
        <p>There is no vesting schedule for [{{scheduleOwner}}]</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { ethers } from 'ethers'
import TransactionTable from '@/components/Dashboard/TransactionTable'

export default {
  name: 'Admin',
  computed: {
    ...mapGetters(['vestingContract', 'getHistoriesForAddress', 'account', 'canAccountViewAdmin']),
    scheduleWithHumanReadableValues () {
      if (!this.schedule) return null
      return {
        amount: ethers.utils.formatEther(this.schedule._amount),
        remainingBalance: ethers.utils.formatEther(this.schedule._remainingBalance),
        totalDrawn: ethers.utils.formatEther(this.schedule._totalDrawn),
        drawDownRate: ethers.utils.formatEther(this.schedule._drawDownRate),
        cliff: new Date(this.schedule._cliff * 1000),
        lastDrawnAt: new Date(this.schedule._lastDrawnAt * 1000),
        start: new Date(this.schedule._start * 1000),
        end: new Date(this.schedule._end * 1000)
      }
    }
  },
  components: {
    TransactionTable
  },
  data () {
    return {
      scheduleOwner: '',
      schedule: null,
      scheduleEvents: []
    }
  },
  mounted () {
    if (!this.vestingContract) {
      window.location = '/sign-in?next=admin'
    } else {
      const canViewAdmin = this.canAccountViewAdmin(this.account)

      if (!canViewAdmin) {
        this.$router.push({
          name: 'dashboard'
        })
      }
    }
  },
  methods: {
    async getVestingSchedule () {
      this.schedule = await this.vestingContract.methods.vestingScheduleForBeneficiary(this.scheduleOwner).call()
      this.scheduleEvents = await this.getHistoriesForAddress(this.scheduleOwner)
      console.log(this.scheduleEvents)
    },
    clear () {
      this.schedule = null
      this.scheduleEvents = []
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <v-container
    class="fill-height"
    fluid
  >
    <!-- Sign in -->
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        align="center"
        justify="center"
      >
        <sign-in-card
          v-if="!error"
          :loading="loading"
          @sign-in-click="handleSignIn"
        />
        <error-card
          v-if="error"
          :title="error.title"
          :body="error.body"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import web3Connect from '@/web3ConnectService'
import SignInCard from '@/components/SignInCard'
import ErrorCard from '@/components/ErrorCard'

export default {
  name: 'SignIn',
  components: {
    SignInCard,
    ErrorCard
  },
  data () {
    return {
      error: null,
      loading: false
    }
  },
  computed: {
    ...mapState([
      'account',
      'schedule',
      'web3'
    ]),
    ...mapGetters([
      'hasValidSchedule',
      'toEther',
      'toEtherFixed',
      'account',
      'canAccountViewAdmin'
    ])
  },
  watch: {
    schedule (value) {
      const { next } = this.$route.query
      if (next && next === 'admin' && this.canAccountViewAdmin(this.account)) {
        this.$router.push({
          name: 'admin'
        })
      } else if (value && this.hasValidSchedule()) {
        this.$router.push({
          name: 'dashboard'
        })
      } else {
        if (this.canAccountViewAdmin(this.account)) {
          this.$router.push({
            name: 'admin'
          })

          return
        }

        this.error = {
          title: 'Access denied',
          body: 'Access is dependant on your vesting release date. Please check with your Account Manager for further information.' + '\n' + 'Your wallet address used: ' + this.account
        }
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.polling)
  },
  created () {
    this.pollData()

    web3Connect.on('connect', provider => {
      this.$store.dispatch('bootstrap', provider)
    })
    // subscribe to close
    web3Connect.on('close', () => {
      this.loading = false
    })

    web3Connect.on('error', () => {
      this.loading = false
    })
  },
  methods: {
    handleSignIn () {
      this.loading = true
      web3Connect.toggleModal()
    },
    pollData () {
      this.polling = setInterval(() => {
        this.$store.dispatch('availableDrawDownAmount')
      }, 10000)
    }
  }
}
</script>

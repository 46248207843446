<template>
  <div>
    <v-card
      style="background-color:transparent; max-width:480px"
      class="text-left"
      flat
    >
      <h1
        class="display-2 font-weight-bold mb-3"
        v-text="'CUDOS Token Holder Portal'"
      />
      <v-card
        class="text-left dark-card"
        flat
      >
        <v-card-title>
          <h2 v-text="title" />
        </v-card-title>
        <v-card-text>
          <p v-text="body" />
          <p v-text="'If you belive this is a mistake please contact CUDOS'" />
          <contact-cudos-button />
        </v-card-text>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import ContactCudosButton from '@/components/ContactCudosButton'
export default {
  name: 'ErrorCard',
  components: {
    ContactCudosButton
  },
  props: {
    title: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: true
    }
  }
}
</script>

<style>

</style>

<template>
  <v-card
    class="text-left dark-card"
    flat
  >
    <h2
      class="subheading mx-4 pt-5 mb-3"
      v-text="title"
    />
    <v-card-text>
      <template v-if="Array.isArray(body)">
        <p
          v-for="(paragraph, index) in body"
          :key="index"
          v-text="paragraph"
        />
      </template>
      <p
        v-else
        v-text="body"
      />
      <contact-cudos-button v-if="shouldShowContactButton" />
    </v-card-text>
  </v-card>
</template>

<script>
import ContactCudosButton from '@/components/ContactCudosButton'

export default {
  name: 'FaqCard',
  components: {
    ContactCudosButton
  },
  props: {
    title: {
      type: String,
      required: true
    },
    body: {
      type: [String, Array],
      required: true
    },
    shouldShowContactButton: {
      type: Boolean,
      default: false
    }
  }
}
</script>
